import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { useAddons } from '@/hooks/useAppsite';
import useFeedback from '@/hooks/useFeedback';
import useSdk from '@/hooks/useSdk';
import { classNames } from '@/util/classNames';
import dayjs from '@/util/dayjs';
import { DiscountCampaign } from '@entrecode/dm-types/types/DiscountCampaign';
import EntryResource from 'ec.sdk/lib/resources/publicAPI/EntryResource';
import React, { useContext } from 'react';
import Button from '../Button';
import Smart from '../SmartForm';
import Spinner from '../Spinner';

const SellingForm = () => {
  const { membershipTemplate: mt, selectedVersion } = useContext(OMTContext);
  const { withFeedback, pending } = useFeedback();
  const membershipTemplate = mt as DiscountCampaign & EntryResource;
  const { api } = useSdk();
  const { data: addons } = useAddons();

  if (membershipTemplate.type === 'addon' && !(addons && selectedVersion)) return <Spinner />;
  return (
    <div>
      <Smart.Form
        key={selectedVersion?.id}
        defaultValues={{
          images: membershipTemplate.images,
          name: membershipTemplate.name,
          description: membershipTemplate.description,
          activatedForSale: membershipTemplate.activatedForSale,
          discountPrice: selectedVersion?.hectorSettings.discountPrice,
          dateStart: membershipTemplate.dateStart,
          dateEnd: membershipTemplate.dateEnd,
          tags: membershipTemplate.tags,
          addons: addons
            ?.filter((addon) => selectedVersion?.hectorSettings.addons?.includes(addon.id))
            .reduce((acc, addon) => ({ ...acc, [addon.backendId]: addon }), {}),
        }}
        onSubmit={(values) => {
          withFeedback(
            async () => {
              membershipTemplate.name = values.name;
              membershipTemplate.description = values.description;
              membershipTemplate.images = values.images;
              membershipTemplate.activatedForSale = values.activatedForSale;
              if (values.dateStart) membershipTemplate.dateStart = dayjs(values.dateStart).format('YYYY-MM-DD');
              if (values.dateEnd) membershipTemplate.dateEnd = dayjs(values.dateEnd).format('YYYY-MM-DD');

              // if (selectedVersion) {
              //   selectedVersion.hectorSettings.discountPrice = +String(values.discountPrice.toFixed(2)).replace(
              //     '.',
              //     '',
              //   );
              //   await selectedVersion.save();
              // }

              membershipTemplate.tags = [];
              for (const tag of values.tags) {
                if (typeof tag === 'string') {
                  const newTag = await api.createEntry('discount_campaign_tag', { tag: tag });
                  membershipTemplate.tags.push(newTag);
                } else {
                  membershipTemplate.tags.push(tag);
                }
              }

              await membershipTemplate.save();
            },
            {
              success: 'Erfolgreich gespeichert',
              error: 'Fehler beim Speichern',
            },
          );
        }}
      >
        <div className={classNames(!membershipTemplate.activatedForSale && 'opacity-50 pointer-events-none')}>
          <Smart.Toggle
            label={
              <>
                Gutschein verkaufen?
                <br />
                <small>Kann nachträglich nur deaktiviert werden.</small>
              </>
            }
            name="activatedForSale"
          />
        </div>
        <Smart.File label="Bild" group="settings-images" name="images" />
        <Smart.Input label="Name" name="name" />
        <Smart.Textarea label="Beschreibung" name="description" />
        <div className="border-t pt-5 opacity-50 pointer-events-none">
          <Smart.Price
            cents
            label={
              <>
                Gutschein Preis
                <br />
                <small>Kann nachträglich nicht verändert werden.</small>
              </>
            }
            name="discountPrice"
          />
        </div>
        <Smart.Date label="gültig von" name="dateStart" />
        <Smart.Date label="gültig bis" name="dateEnd" />
        <Smart.TagSelect label="Tags" name="tags" model="discount_campaign_tag" />
        {membershipTemplate.type === 'addon' && <Smart.AddonSelect label="Addons" name="addons" />}
        <div className="flex sticky bottom-0 bg-white dark:bg-gray-600 p-4 justify-end">
          <Button $primary loading={pending}>
            Speichern
          </Button>
        </div>
      </Smart.Form>
    </div>
  );
};

export default SellingForm;
